.flex {
    flex-wrap: wrap;


    &__col{

        @for $i from 2 to 13 {
            &-#{$i} > *{
                width: calc( #{division(100%, $i)} - #{$grid-gap-x});
                min-width: 250px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                align-items: center;
                flex-direction: column;
            }
        }
    }

    &__cel {
        flex-grow: 1;
        padding: calc(#{$padding-base} / 2);
        @include background('secondaire');
        
    }

    &__maxContent{
        .flex__cel{
            flex-grow: initial
        }

    }
}

.grid-flex{
    display: flex;
    justify-content: space-between;
    // align-items: flex-start;
    gap: 2rem;
    flex-wrap: wrap;
    align-items: stretch;

    .col{
        flex: 1 1 0%;
        width: 100%;
        min-width: 300px;
    }
}
