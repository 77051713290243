.slider{

    max-width: 100vw;
    margin: auto;

    .splide{
    
        &.default{
            max-width: 60rem;
            margin: auto;
        }
    
        // &__content{
        //     max-width: 70%;
        //     margin: auto;

        //     @media screen and (min-width: 768px){ 
        //         max-width: 80%;
        //     }
        // }
    
        &__pagination{
            bottom: initial;
            gap: 1rem;
            margin: 1rem auto;
            justify-content: start;
            padding: 0;
        }

        &__arrows{
            display: none;
        }

        &__arrow {
            background: transparent;
            color: $secondaire;
            opacity: 1;

            .icones{
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }
}
