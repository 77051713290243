
.titles{

    $marginBottom: 2rem;

    margin-bottom: $marginBottom;
    width: fit-content;
    font-size: 2rem;
    color: $secondaire;
    // font-weight: 500;


    &__ontitle{
        font-size: .8em;
        line-height: 1em;
        margin: 0;
        text-transform: uppercase;
    }

    &__barreBottom{
        display: block;
        width: 2.2rem;
        height: 5px;
        background: $secondaire;
    }

    &__subtitle{
        font-size: .6em;
        line-height: 1em;
        margin: 0;
    }


    &__h1,
    &__h2,
    &__h3,
    &__h4{
        line-height: 1.1em;
        margin: .1em 0;
    }


    &__h1{
        font-size: clamp(1.4em, 5vw, 3em);
        font-family: $font-2;
    }

    &__h2{
        font-size: 1.5em;
    }

    &__h3{
        font-size: 1.2em;
    }

    &__h4{
        font-size: .8em;
        line-height: 1.8em;
        
    }


    // STYLES


    &__center{
        margin: 0 auto $marginBottom auto;

        .titles__ontitle{
            text-align: center;
        }
    }
    &__right{
        margin: 0 0 $marginBottom auto;
    }

    &__shadow,
    &__border{
        padding: $padding-base;
    }

    &__shadow{
        padding: $padding-base;
        box-shadow: 0px 0px 11px 4px lighten(black, 90%);
    }
    &__border{
        border: 1px solid $primaire;
    }

}