.testimonial {
    gap: 0;
    height: fit-content;

    &__content {
        background: white;
        z-index: 5;
        padding-bottom: 5rem;

        @media screen and (min-width: 768px){ 
            padding: 3rem;
            margin: 3rem 0;
        }

    }

    &__image{
        padding: 0;
        position: relative;

        @media screen and (min-width: 768px){ 
            left: -20%;
            width: 120%;
        }
    }
}