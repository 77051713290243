.images{

    &__cover{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.imageCol{
    @extend .grid-flex;
    gap: 2rem 3rem !important;
    
    img{
        height: 300px;
    }

    &2,
    &3{
        @extend .imageCol;
        @extend .wrapper;
        @extend .sections__max74;

        @media screen and (max-width: 767px){ 
            flex-direction: column;
        }
    }

}