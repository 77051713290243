.masonry{

    display: grid;
    gap: 1rem;
    

    &__item{
        height: 300px;
        position: relative;
        overflow: hidden;

        &.row-span-1{
            height: auto;

            @media screen and (max-width: 639px){ 
                height: 300px;
            }
        }

        .titles{
            margin-bottom: .5rem;
        }

        &:hover,
        &:focus{
            .masonry__wrapper{
                @extend %transition;
                height: 100%;
            }

            .images__cover{
                @extend %transition;
                transform: translateX(10px) scale(1.1);
            }

            .barre{
                @extend %transition;
                width: 100%
            }
        }
    }

    &__wrapper{
        @extend %absolute0;
        top: initial;
        height: 0;
        overflow: hidden;
    }

    &__content{
        width: 80%;
        margin: auto;
        background: white;
        padding: 2rem;
        z-index: 5;
        position: absolute;
        bottom: 0%;
        transform: translateX(-50%) translateY(-20%);
        left: 50%;
        max-height: 200px;
        overflow: hidden;
    }

    .barre{
        height: 0.2rem;
        background: white;
        width: 0;
        position: absolute;
        bottom: 20%;
    }






}