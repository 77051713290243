&__select{
    [x-cloak] {
        display: none;
    }

    &-all{
        display: block;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    .forms__input{
        display: flex;
    }
}