&__bullet {

    @extend .list;

    .list__item,
    li{

        $size: .4rem;
    
        display: flex;
        position: relative;
        padding-left: addition($size, .8rem);
    
        &:before{
            content: '';
            height:  $size;
            width:  $size;
            background-color: $secondaire;
            margin-right:  $size;
            display: inline-flex;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}