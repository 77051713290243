.breadcrumbs{

    &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        text-transform: uppercase;
    }

    &__items {
        display: flex;
    
        &::after {
            display: block;
            content: '';
            width: .5rem;
            mask: url("data:image/svg+xml,%3Csvg width='5' height='15' viewBox='0 0 5 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M3.63636 0H5L1.36364 15H0L3.63636 0Z' fill='%23C4C4C4'/%3E %3C/svg%3E");
            mask-repeat: no-repeat;
            mask-size: cover;
            mask-position: center;
            margin: 0 .5rem;
            background-color: $secondaire;
        }

        &:last-child {
            color: $secondaire;

            &:after{
                display: none;
            }
        }
    }

    &__name {
        display: flex;
        align-items: center;
        height: 1rem;
    }

    &__links{

        color: white;

        &:hover,
        &:focus{
            color: $secondaire;
            @extend %transition;
        }
    }
}
