/* _video
========================================================================== */
 
/* Video
   ========================================================================== */

.video.video-js {
    width: 100%;
    max-height: 550px;
    height: 100%;
    aspect-ratio: 1;
    margin: auto;
    display: flex;

    .vjs-big-play-button {
        font-size:3em;
        width:2em;
        height:2em;
        position:absolute;
        top:50%;
        left:50%;
        background-color:var(--color2-500);
        border-radius:0;
        transition:all .4s;
        border: none;
        display: block;
        transform: translate(20%);
    }

    .vjs-icon-placeholder{
        display: flex;
    }

    .vjs-big-play-button .vjs-icon-placeholder::before{
        position: relative;
        color: white !important;
    }

    &:hover{
        .vjs-big-play-button{
            background-color:var(--color2-800);
        }
    }
}

.vjs-playing .vjs-big-play-button{
    display: none !important;
}
