.headers{
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin: auto;
    // max-width: 80rem;
    width: 100%;
    color: white;

    @media screen and (min-width: 640px){ 
        padding: 6rem 0rem;
    }


    &__image{
        position: relative;

        &-wrapper{
            @extend %absolute0;
        }

        .wrapper > * {
            z-index: 10;
            position: relative ;
        }

        .titles{
            margin: 0;

            &__center{
                margin: auto;
            }
        }
    }

    &__slider{
        position: relative;

        .splide__pagination{
            z-index: 50;
        }

        .pagination.splide__pagination__page{
            background: white;
        }

        .splide__list{
            min-height: 800px;

            @media screen and (max-width: 639px){ 
                min-height: 1000px;
            }
        }

        .splide__content{
            height: 100%;

            position: relative;

            &:before{
                content: '';
                width: 100%;
                height: 100%;
                display: block;
                background: $tertiaire-8;
                opacity: .6;
                @extend %absolute0;
            }
        }
    }

    &__content{
        @extend %absolute0;
        @extend .wrapper;
        z-index: 10;
        height: fit-content;
        top: 50%;
        transform: translateY(-50%);
        color: white;
     }

}
