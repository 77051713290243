&__file{

    width: 0;
	height: 0;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;

    & + label {

        display: inline-block;
        cursor: pointer;
        width: 100%;
        text-align: center;

        &:after{
            display: none;
        }
    }
}