

a, a.link{

    display: inline-block;
    color: $secondaire;

    &:hover,
    &:focus,
    &:focus-visible {
        @extend %transition;
        color: $secondaire-3;
        outline: none;
    }


    &__crypte:after {
        content: attr(data-name) "@"attr(data-domain);
    }

    &__light{

        color: $secondaire-3;

        &:hover,
        &:focus,
        &:focus-visible {
            @extend %transition;
            color: $secondaire;
        }
    }

    &__white{

        color: white;

        &:hover,
        &:focus,
        &:focus-visible {
            @extend %transition;
            color: $secondaire;
        }
    }

    &__icones{
        display: flex;
        align-items: center;
       
        .icones{
            margin-right: .3rem;
            @extend %transition;
        }

        &:hover,
        &:focus{
            .icones{
                transform: translateX(-5px);
            }
        }
    }

}