.cta__image, .cta__content, .masonry__wrapper, .team__content, .errorPage__image:before, .errorPage__image, .headers__content, .headers__slider .splide__content:before, .headers__image-wrapper, .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.masonry__item:hover .barre, .masonry__item:focus .barre, .masonry__item:hover .images__cover, .masonry__item:focus .images__cover, .masonry__item:hover .masonry__wrapper, .masonry__item:focus .masonry__wrapper, .team__content, .breadcrumbs__links:hover, .breadcrumbs__links:focus, .pagination__arrows:hover, .pagination__active, .footer .link:hover, .footer .link:focus, .nav .link.active:before, .menu .dropdown .link span, .menu .dropdown .link, .menu__bg, a__icones .icones, a.link__icones .icones, a__white:hover, a__white:focus, a__white:focus-visible, a.link__white:hover, a.link__white:focus, a.link__white:focus-visible, a__light:hover, a__light:focus, a__light:focus-visible, a.link__light:hover, a.link__light:focus, a.link__light:focus-visible, a:hover, a:focus, a:focus-visible, a.link:hover, a.link:focus, a.link:focus-visible, #topButton, .button:hover, .button:focus {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.4s;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
::selection {
  background-color: var(--color2-100);
  color: var(--color1-500);
}

html {
  overflow-x: hidden;
  font-weight: 200;
  font-size: 16px;
  font-family: var(--font-family1);
  scroll-behavior: smooth;
  color: var(--color1-500);
}

.wrapper, .headers__content, .imageCol2, .imageCol3 {
  margin-left: auto;
  margin-right: auto;
  padding-right: 2rem;
  padding-left: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.wrapper .container, .headers__content .container, .imageCol2 .container, .imageCol3 .container {
  width: 100%;
}
@media (min-width: 640px) {
  .wrapper, .headers__content, .imageCol2, .imageCol3 {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .wrapper, .headers__content, .imageCol2, .imageCol3 {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .wrapper, .headers__content, .imageCol2, .imageCol3 {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .wrapper, .headers__content, .imageCol2, .imageCol3 {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .wrapper, .headers__content, .imageCol2, .imageCol3 {
    max-width: 1536px;
  }
}
@media screen and (min-width: 768px) {
  .wrapper, .headers__content, .imageCol2, .imageCol3 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

.ancres {
  padding-top: 11rem !important;
  margin-top: -8rem !important;
}

.text-color1-100 {
  color: var(--color1-100);
}

.bg-color1-100 {
  background-color: var(--color1-100);
}

.text-color1-200 {
  color: var(--color1-200);
}

.bg-color1-200 {
  background-color: var(--color1-200);
}

.text-color1-300 {
  color: var(--color1-300);
}

.bg-color1-300 {
  background-color: var(--color1-300);
}

.text-color1-400 {
  color: var(--color1-400);
}

.bg-color1-400 {
  background-color: var(--color1-400);
}

.text-color1-500 {
  color: var(--color1-500);
}

.bg-color1-500 {
  background-color: var(--color1-500);
}

.text-color1-600 {
  color: var(--color1-600);
}

.bg-color1-600 {
  background-color: var(--color1-600);
}

.text-color1-700 {
  color: var(--color1-700);
}

.bg-color1-700 {
  background-color: var(--color1-700);
}

.text-color1-800 {
  color: var(--color1-800);
}

.bg-color1-800 {
  background-color: var(--color1-800);
}

.text-color1-900 {
  color: var(--color1-900);
}

.bg-color1-900 {
  background-color: var(--color1-900);
}

.text-color2-100 {
  color: var(--color2-100);
}

.bg-color2-100 {
  background-color: var(--color2-100);
}

.text-color2-200 {
  color: var(--color2-200);
}

.bg-color2-200 {
  background-color: var(--color2-200);
}

.text-color2-300 {
  color: var(--color2-300);
}

.bg-color2-300 {
  background-color: var(--color2-300);
}

.text-color2-400 {
  color: var(--color2-400);
}

.bg-color2-400 {
  background-color: var(--color2-400);
}

.text-color2-500 {
  color: var(--color2-500);
}

.bg-color2-500 {
  background-color: var(--color2-500);
}

.text-color2-600 {
  color: var(--color2-600);
}

.bg-color2-600 {
  background-color: var(--color2-600);
}

.text-color2-700 {
  color: var(--color2-700);
}

.bg-color2-700 {
  background-color: var(--color2-700);
}

.text-color2-800 {
  color: var(--color2-800);
}

.bg-color2-800 {
  background-color: var(--color2-800);
}

.text-color2-900 {
  color: var(--color2-900);
}

.bg-color2-900 {
  background-color: var(--color2-900);
}

.text-color3-100 {
  color: var(--color3-100);
}

.bg-color3-100 {
  background-color: var(--color3-100);
}

.text-color3-200 {
  color: var(--color3-200);
}

.bg-color3-200 {
  background-color: var(--color3-200);
}

.text-color3-300 {
  color: var(--color3-300);
}

.bg-color3-300 {
  background-color: var(--color3-300);
}

.text-color3-400 {
  color: var(--color3-400);
}

.bg-color3-400 {
  background-color: var(--color3-400);
}

.text-color3-500 {
  color: var(--color3-500);
}

.bg-color3-500 {
  background-color: var(--color3-500);
}

.text-color3-600 {
  color: var(--color3-600);
}

.bg-color3-600 {
  background-color: var(--color3-600);
}

.text-color3-700 {
  color: var(--color3-700);
}

.bg-color3-700 {
  background-color: var(--color3-700);
}

.text-color3-800 {
  color: var(--color3-800);
}

.bg-color3-800 {
  background-color: var(--color3-800);
}

.text-color3-900 {
  color: var(--color3-900);
}

.bg-color3-900 {
  background-color: var(--color3-900);
}

:root {
  --color1-100: #d5d5d5;
  --color1-200: #ababab;
  --color1-300: gray;
  --color1-400: #565656;
  --color1-500: #2c2c2c;
  --color1-600: #232323;
  --color1-700: #1a1a1a;
  --color1-800: #121212;
  --color1-900: #090909;
  --color2-100: #fddddd;
  --color2-200: #fbbabb;
  --color2-300: #fa9898;
  --color2-400: #f87576;
  --color2-500: #f65354;
  --color2-600: #c54243;
  --color2-700: #943232;
  --color2-800: #622122;
  --color2-900: #311111;
  --color3-100: #d0d0d8;
  --color3-200: #a1a1b1;
  --color3-300: #72728a;
  --color3-400: #434363;
  --color3-500: #14143c;
  --color3-600: #101030;
  --color3-700: #0c0c24;
  --color3-800: #080818;
  --color3-900: #04040c;
  --font-family1: futura-pt, sans-serif;
  --font-family2: ailerons, sans-serif;
}

/* _dividers
========================================================================== */
hr,
hr.divider,
hr.separateur {
  border: none;
}

.tags {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  user-select: none;
}
.tags input {
  display: none;
}
.tags input:checked ~ label {
  color: white;
  background-color: var(--color2-500);
}
.tags label,
.tags > * {
  padding: 0.5rem 1rem;
  border: 0.3rem solid var(--color2-500);
  text-transform: uppercase;
  height: auto;
  display: block;
}

.titles {
  margin-bottom: 2rem;
  width: fit-content;
  font-size: 2rem;
  color: var(--color2-500);
}
.titles__ontitle {
  font-size: 0.8em;
  line-height: 1em;
  margin: 0;
  text-transform: uppercase;
}
.titles__barreBottom {
  display: block;
  width: 2.2rem;
  height: 5px;
  background: var(--color2-500);
}
.titles__subtitle {
  font-size: 0.6em;
  line-height: 1em;
  margin: 0;
}
.titles__h1, .titles__h2, .titles__h3, .titles__h4 {
  line-height: 1.1em;
  margin: 0.1em 0;
}
.titles__h1 {
  font-size: clamp(1.4em, 5vw, 3em);
  font-family: var(--font-family2);
}
.titles__h2 {
  font-size: 1.5em;
}
.titles__h3 {
  font-size: 1.2em;
}
.titles__h4 {
  font-size: 0.8em;
  line-height: 1.8em;
}
.titles__center, .text-center .titles {
  margin: 0 auto 2rem auto;
}
.titles__center .titles__ontitle, .text-center .titles .titles__ontitle {
  text-align: center;
}
.titles__right {
  margin: 0 0 2rem auto;
}
.titles__shadow, .titles__border {
  padding: 2rem;
}
.titles__shadow {
  padding: 2rem;
  box-shadow: 0px 0px 11px 4px #e6e6e6;
}
.titles__border {
  border: 1px solid var(--color1-500);
}

/* _icons
========================================================================== */
[class*=" icon-"] {
  line-height: 1;
  z-index: 1;
}

[class^=icon-]:before, [class*=" icon-"]:before {
  font-family: icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: currentColor;
}

[type=button],
.button {
  -webkit-appearance: none;
}

.button {
  background-color: var(--color2-500);
  padding: 1rem 1.5rem;
  display: inline-flex;
  align-items: center;
  font-family: var(--font-family1);
  font-size: 1rem;
  border-radius: 0;
  width: max-content;
  text-transform: uppercase;
  position: relative;
}
.button:after, .button:before {
  content: "";
  width: 0%;
  background: var(--color1-500);
  height: 53%;
  position: absolute;
  right: 0;
  bottom: -1px;
}
.button:after {
  top: -1px;
  left: 0;
}
.button:hover:after, .button:hover:before, .button:focus:after, .button:focus:before {
  transition-property: all;
  transition-timing-function: ease;
  transition-duration: 0.5s;
  width: 100%;
}
.button__content {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  line-height: 1;
  color: white;
}
.button__border {
  border: 0.15rem solid var(--color1-500);
}
.button__icones {
  padding: 1rem;
}
.button .icones {
  width: 1rem;
  height: 1rem;
  z-index: 10;
}
.button__secondary {
  background-color: var(--color2-500);
}
.button__secondary.button__border {
  border-color: var(--color1-500);
}
.button:hover, .button:focus {
  background: var(--color2-500);
  color: white;
  border-color: var(--color1-500);
}
.button__light {
  background: white;
  color: var(--color2-500);
  border-color: var(--color1-500);
}
.button__light .button__content {
  color: var(--color2-500);
}
.button__light:hover .button__content {
  color: white;
}

.button__group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
}

#topButton {
  bottom: 2.5rem;
  right: 2.5rem;
  visibility: hidden;
  background: var(--color2-500);
  padding: 0.5rem;
  color: white;
  width: 3rem;
  height: 3rem;
  z-index: 9999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 768px) {
  #topButton {
    visibility: visible;
  }
}
#topButton.hide {
  transform: translateY(200%);
}

.list, .list__bullet, .footer__list {
  margin: 0.5rem 0;
}
.list__bullet .list__item, .footer__list .list__item, .footer__list li,
.list__bullet li {
  display: flex;
  position: relative;
  padding-left: 1.2rem;
}
.list__bullet .list__item:before, .footer__list .list__item:before, .footer__list li:before,
.list__bullet li:before {
  content: "";
  height: 0.4rem;
  width: 0.4rem;
  background-color: var(--color2-500);
  margin-right: 0.4rem;
  display: inline-flex;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.overlay {
  opacity: 0.6;
}

.images__cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.imageCol, .imageCol2, .imageCol3 {
  gap: 2rem 3rem !important;
}
.imageCol img, .imageCol2 img, .imageCol3 img {
  height: 300px;
}
@media screen and (max-width: 767px) {
  .imageCol2, .imageCol3 {
    flex-direction: column;
  }
}

.headers {
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin: auto;
  width: 100%;
  color: white;
}
@media screen and (min-width: 640px) {
  .headers {
    padding: 6rem 0rem;
  }
}
.headers__image {
  position: relative;
}
.headers__image .wrapper > *, .headers__image .headers__content > *, .headers__image .imageCol2 > *, .headers__image .imageCol3 > * {
  z-index: 10;
  position: relative;
}
.headers__image .titles {
  margin: 0;
}
.headers__image .titles__center, .headers__image .text-center .titles, .text-center .headers__image .titles {
  margin: auto;
}
.headers__slider {
  position: relative;
}
.headers__slider .splide__pagination {
  z-index: 50;
}
.headers__slider .pagination.splide__pagination__page {
  background: white;
}
.headers__slider .splide__list {
  min-height: 800px;
}
@media screen and (max-width: 639px) {
  .headers__slider .splide__list {
    min-height: 1000px;
  }
}
.headers__slider .splide__content {
  height: 100%;
  position: relative;
}
.headers__slider .splide__content:before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: var(--color3-800);
  opacity: 0.6;
}
.headers__content {
  z-index: 10;
  height: fit-content;
  top: 50%;
  transform: translateY(-50%);
  color: white;
}

.forms {
  color: var(--color1-500);
}
.forms.grid {
  gap: 1rem;
}
.forms .grid {
  gap: 1rem;
}
.forms__label {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: var(--color2-500);
  text-transform: uppercase;
}
.forms__input, .forms__textarea {
  display: block;
  padding: 1rem;
  width: 100%;
  border: none;
  border-bottom: 0.1rem solid var(--color2-500);
}
.forms__input:focus, .forms__textarea:focus {
  box-shadow: none;
  transition: border-color 0.5s ease-in-out;
  border-color: var(--color2-500);
}
.forms__textarea {
  resize: none;
  min-height: 10rem;
  width: 100%;
}
.forms__message {
  padding: 0 0.5rem;
  margin: 0.5rem 0;
  color: currentColor;
}
.forms__checkbox {
  position: relative;
  display: flex;
  align-items: flex-start;
  color: currentColor;
}
.forms__checkbox-inputWrapper {
  display: flex;
  align-items: center;
  height: 1.8rem;
}
.forms__checkbox-input {
  width: 1rem;
  height: 1rem;
  color: currentColor;
}
.forms__checkbox-input:focus {
  outline-offset: 0px;
  box-shadow: none;
}
.forms__checkbox .forms__label {
  color: var(--color1-500);
  text-transform: initial;
  display: inline;
  padding: 0 0.5rem;
}
.forms__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.forms__select [x-cloak] {
  display: none;
}
.forms__select-all {
  display: block;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.forms__select .forms__input {
  display: flex;
}
.forms__mutiSelect {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
}
.forms__mutiSelect-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}
.forms__mutiSelect-input {
  flex: 1 1 0%;
}
.forms__mutiSelect-input input {
  width: 100%;
  height: 100%;
  padding: 0.25rem 0.5rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.forms__mutiSelect-chevrons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: -1rem;
  color: white;
  background: var(--color1-500);
}
.forms__mutiSelect-chevrons [type=button] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.forms__mutiSelect-dropdown {
  width: 100%;
}
.forms__mutiSelect-dropdown-wrapper {
  position: absolute;
  left: 0;
  z-index: 40;
  width: 100%;
  background: white;
  box-shadow: var(--shadow-b);
}
.forms__mutiSelect-dropdown-options {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
}
.forms__mutiSelect-dropdown-option {
  overflow: auto;
}
.forms__mutiSelect-dropdown-item {
  width: 100%;
  border-bottom-width: 1px;
  border-color: var(--color1-500);
  cursor: pointer;
}
.forms__mutiSelect-dropdown-item:last-child {
  border-bottom: none;
}
.forms__mutiSelect .forms__input-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
}
.forms__mutiSelect .forms__input-options {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  margin: 0.25rem;
}
.forms__mutiSelect .forms__input-selected {
  font-size: 0.75rem;
  line-height: 1rem;
  line-height: 1;
  max-width: 100%;
  flex: 0 1 auto;
}
.forms__mutiSelect .forms__input-icones {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 0.5rem;
}
.forms__file {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.forms__file + label {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  text-align: center;
}
.forms__file + label:after {
  display: none;
}
.forms .quantity {
  position: relative;
}
.forms .quantity input {
  height: 42px;
  line-height: 1.65;
  float: left;
  display: block;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  border: 2px solid var(--color1-500);
  width: 90px;
}
.forms .quantity input:focus {
  outline: 0;
}
.forms input[type=number]::-webkit-inner-spin-button,
.forms input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.forms .quantity-nav {
  float: left;
  position: relative;
  height: 42px;
}
.forms .quantity-button {
  position: relative;
  cursor: pointer;
  width: 20px;
  text-align: center;
  color: white;
  font-size: 10px;
  line-height: 1.7;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  user-select: none;
  background: var(--color1-500);
}
.forms .quantity-button--up {
  position: absolute;
  height: 50%;
  top: 0;
  mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-8 -5 24 24' width='24' fill='currentColor'%3E%3Cpath d='M5.314 7.071l-4.95-4.95A1 1 0 0 1 1.778.707l5.657 5.657a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 0 1-1.414-1.414l4.95-4.95z'%3E%3C/path%3E%3C/svg%3E");
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: cover;
}
.forms .quantity-button--down {
  position: absolute;
  bottom: 0;
  height: 50%;
  mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-8 -5 24 24' width='24' fill='currentColor'%3E%3Cpath d='M5.314 7.071l-4.95-4.95A1 1 0 0 1 1.778.707l5.657 5.657a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 0 1-1.414-1.414l4.95-4.95z'%3E%3C/path%3E%3C/svg%3E");
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: cover;
}
.forms__inline {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
}
.forms__inline .quantity input,
.forms__inline .quantity-nav {
  height: 100%;
  border: none;
}
.forms__inline .button {
  margin: 0;
  flex: 1;
}
.forms__inline .button__content {
  margin: auto;
}
.forms__inline .quantity-button {
  height: 25px;
}
.forms__inline .quantity-button--up {
  transform: rotate(270deg) translate(-30%, -100%);
}
.forms__inline .quantity-button--down {
  transform: rotate(90deg) translate(-30%, 100%);
}
.forms [type=submit] {
  min-width: 200px;
  justify-content: center;
  margin-top: 1rem;
}
.forms .grid__col-2 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
@media screen and (min-width: 1280px) {
  .forms .grid__col-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.filters__options {
  display: flex;
  flex-wrap: wrap;
}
.filters__header {
  background: var(--color3-800);
  padding: 2rem;
}
@media screen and (min-width: 640px) {
  .filters__header {
    padding: 3rem;
    max-width: 80rem;
    margin: auto;
    margin-bottom: 4rem;
  }
}
.filters__header .titles {
  color: white;
}
.filters .filter {
  border: 0.15rem solid white;
  color: white;
}
.filters .filter--selected {
  border-color: var(--color2-500);
  background-color: var(--color2-500);
}

.cardfilter.show {
  display: block;
}
.cardfilter.hide {
  display: none;
}

.cards {
  display: grid;
  gap: 0 2rem;
}
@media screen and (min-width: 640px) {
  .cards__content {
    padding: 2rem;
  }
}
@media screen and (min-width: 768px) {
  .cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .cards__inverse .cards__image {
    order: 2;
  }
}

.badges {
  position: absolute;
  top: 0;
  left: 0;
}

.grid__col-2 {
  gap: 2rem 2rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid__col-3 {
  gap: 2rem 2rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid__col-4 {
  gap: 2rem 2rem;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid__col-5 {
  gap: 2rem 2rem;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid__col-6 {
  gap: 2rem 2rem;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid__col-7 {
  gap: 2rem 2rem;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
.grid__col-8 {
  gap: 2rem 2rem;
  grid-template-columns: repeat(8, minmax(0, 1fr));
}
.grid__col-9 {
  gap: 2rem 2rem;
  grid-template-columns: repeat(9, minmax(0, 1fr));
}
.grid__col-10 {
  gap: 2rem 2rem;
  grid-template-columns: repeat(10, minmax(0, 1fr));
}
.grid__col-11 {
  gap: 2rem 2rem;
  grid-template-columns: repeat(11, minmax(0, 1fr));
}
.grid__col-12 {
  gap: 2rem 2rem;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
.grid__col-dense {
  grid-auto-flow: dense;
}
.grid__cel {
  padding: calc(2rem / 2);
}
@media screen and (max-width: 639px) {
  .grid__col-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.grid__product {
  gap: 5rem;
  margin: 3rem auto !important;
}

.flex {
  flex-wrap: wrap;
}
.flex__col-2 > * {
  width: calc( 50% - 2rem);
  min-width: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.flex__col-3 > * {
  width: calc( 33.3333333333% - 2rem);
  min-width: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.flex__col-4 > * {
  width: calc( 25% - 2rem);
  min-width: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.flex__col-5 > * {
  width: calc( 20% - 2rem);
  min-width: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.flex__col-6 > * {
  width: calc( 16.6666666667% - 2rem);
  min-width: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.flex__col-7 > * {
  width: calc( 14.2857142857% - 2rem);
  min-width: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.flex__col-8 > * {
  width: calc( 12.5% - 2rem);
  min-width: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.flex__col-9 > * {
  width: calc( 11.1111111111% - 2rem);
  min-width: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.flex__col-10 > * {
  width: calc( 10% - 2rem);
  min-width: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.flex__col-11 > * {
  width: calc( 9.0909090909% - 2rem);
  min-width: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.flex__col-12 > * {
  width: calc( 8.3333333333% - 2rem);
  min-width: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.flex__cel {
  flex-grow: 1;
  padding: calc(2rem / 2);
  background-color: var(--color2-500);
}
.flex__maxContent .flex__cel {
  flex-grow: initial;
}

.grid-flex, .imageCol, .imageCol2, .imageCol3 {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
  align-items: stretch;
}
.grid-flex .col, .imageCol .col, .imageCol2 .col, .imageCol3 .col {
  flex: 1 1 0%;
  width: 100%;
  min-width: 300px;
}

a, a.link {
  display: inline-block;
  color: var(--color2-500);
}
a:hover, a:focus, a:focus-visible, a.link:hover, a.link:focus, a.link:focus-visible {
  color: var(--color2-300);
  outline: none;
}
a__crypte:after, a.link__crypte:after {
  content: attr(data-name) "@" attr(data-domain);
}
a__light, a.link__light {
  color: var(--color2-300);
}
a__light:hover, a__light:focus, a__light:focus-visible, a.link__light:hover, a.link__light:focus, a.link__light:focus-visible {
  color: var(--color2-500);
}
a__white, a.link__white {
  color: white;
}
a__white:hover, a__white:focus, a__white:focus-visible, a.link__white:hover, a.link__white:focus, a.link__white:focus-visible {
  color: var(--color2-500);
}
a__icones, a.link__icones {
  display: flex;
  align-items: center;
}
a__icones .icones, a.link__icones .icones {
  margin-right: 0.3rem;
}
a__icones:hover .icones, a__icones:focus .icones, a.link__icones:hover .icones, a.link__icones:focus .icones {
  transform: translateX(-5px);
}

.menu {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
@media screen and (max-width: 767px) {
  .menu {
    background: var(--color3-800);
  }
}
.menu .wrapper, .menu .imageCol2, .menu .imageCol3, .menu .headers__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: initial;
  padding: 2rem;
}
@media screen and (max-width: 767px) {
  .menu .wrapper, .menu .imageCol2, .menu .imageCol3, .menu .headers__content {
    flex-direction: column;
    align-items: self-start;
  }
}
@media screen and (min-width: 1024px) {
  .menu .wrapper, .menu .imageCol2, .menu .imageCol3, .menu .headers__content {
    margin-left: auto;
    margin-right: auto;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .menu .wrapper .container, .menu .imageCol2 .container, .menu .imageCol3 .container, .menu .headers__content .container {
    width: 100%;
  }
}
@media screen and (min-width: 1024px) and (min-width: 640px) {
  .menu .wrapper, .menu .imageCol2, .menu .imageCol3, .menu .headers__content {
    max-width: 640px;
  }
}
@media screen and (min-width: 1024px) and (min-width: 768px) {
  .menu .wrapper, .menu .imageCol2, .menu .imageCol3, .menu .headers__content {
    max-width: 768px;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1024px) {
  .menu .wrapper, .menu .imageCol2, .menu .imageCol3, .menu .headers__content {
    max-width: 1024px;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1280px) {
  .menu .wrapper, .menu .imageCol2, .menu .imageCol3, .menu .headers__content {
    max-width: 1280px;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1536px) {
  .menu .wrapper, .menu .imageCol2, .menu .imageCol3, .menu .headers__content {
    max-width: 1536px;
  }
}
@media screen and (min-width: 768px) {
  .menu + * {
    margin-top: -105px;
  }
}
.menu__logo {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
}
.menu__logo img {
  display: block;
  width: auto;
  height: 2rem;
}
.menu__bg {
  position: absolute;
  left: 0;
  right: 0;
  background: var(--color3-800);
  bottom: 100%;
  top: 0;
}
.menu.show .menu__bg {
  bottom: 0;
}
.menu.show.hide .menu__bg {
  bottom: 100%;
}
.menu .link {
  padding: 0.5rem;
  color: white;
}
.menu .dropdown .link {
  color: var(--color2-500);
  display: flex;
  align-items: center;
  color: var(--color3-800);
}
.menu .dropdown .link span {
  width: 0;
  background: var(--color2-500);
  display: block;
  height: 0.1rem;
  margin-right: 0.5rem;
}
.menu .dropdown .link:hover {
  color: var(--color2-500);
}
.menu .dropdown .link:hover span {
  width: 2rem;
}

#navCheck {
  position: absolute;
  left: -999;
  top: -999;
  visibility: hidden;
}

@media screen and (max-width: 767px) {
  #navCheck:checked ~ .nav {
    padding: 1rem 2rem;
    height: 100%;
    width: 100%;
  }
}

#navCheck ~ .buttons .menu__button .icones__close {
  display: none;
}

#navCheck:checked ~ .buttons .menu__button .icones__burger {
  display: none;
}

#navCheck:checked ~ .buttons .menu__button .icones__close {
  display: block;
}

@media screen and (min-width: 768px) {
  .menu__button {
    display: none;
  }
}

.nav {
  display: flex;
  align-items: center;
  z-index: 999;
}
@media screen and (max-width: 767px) {
  .nav {
    flex-direction: column;
    align-items: self-start;
    height: 0;
    overflow: auto;
    padding: 0;
  }
}
.nav__item {
  display: inline-flex;
  align-items: center;
  padding: 1rem;
  text-decoration: none;
  color: white;
}
.nav__item:hover {
  color: #e6e6e6;
}
.nav .link.active {
  position: relative;
}
.nav .link.active:before {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background: var(--color2-500);
  position: absolute;
  bottom: 0;
  left: 0;
}
.nav .link.active:hover:before {
  left: 0.2rem;
}

.dropdown {
  position: relative;
}
@media screen and (min-width: 768px) {
  .dropdown {
    z-index: -1;
  }
}
.dropdown__btn {
  position: relative;
  display: inline-flex;
  padding: 0.5rem;
  border: none;
  font-size: inherit;
  color: white;
  font-family: inherit;
  cursor: pointer;
  background-color: transparent;
  border-radius: 3px;
  z-index: 2;
}
.dropdown__list {
  position: absolute;
  left: 0;
  width: max-content;
  z-index: -1;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  overflow: hidden;
  transform: translateY(-20%);
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s;
  z-index: 1;
  background: white;
  padding: 1rem;
}
.dropdown__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  text-decoration: none;
  color: var(--color2-500);
  background-color: rgba(112, 102, 204, 0.1);
  transition: background-color 0.2s;
}
.dropdown__item:first-child {
  border-radius: 3px 3px 0 0;
}
.dropdown__item:hover {
  background-color: var(--color2-500);
}
.dropdown__item span {
  height: 1rem;
  width: 1rem;
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.65rem;
  color: #fff;
  background-color: #7066cc;
  border-radius: 50%;
}
.dropdown:hover .dropdown__btn {
  color: var(--color2-500);
}
.dropdown:hover .dropdown__list {
  transform: translateY(10px);
  visibility: visible;
  opacity: 1;
}
.dropdown .link.active:before {
  display: none;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  background: var(--color2-500);
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
}

.footer {
  background: var(--color3-800);
  color: white;
}
.footer .grid {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
@media screen and (min-width: 768px) {
  .footer .grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .footer .grid__cel:first-child {
    grid-column: span 3/span 3;
  }
}
@media screen and (min-width: 1024px) {
  .footer .grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .footer .grid__cel:first-child {
    grid-column: span 1/span 1;
  }
}
.footer__logo {
  max-height: 115px;
  margin-bottom: 1.3rem;
}
.footer__list li {
  padding: 0.1rem 1rem;
}
.footer__list li::before {
  background: var(--color2-500);
}
.footer__copyright {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-transform: uppercase;
}
@media screen and (min-width: 640px) {
  .footer__copyright {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .footer__copyright .footer__social {
    direction: rtl;
  }
  .footer__copyright *:nth-child(1) {
    order: 2;
  }
}
.footer__social {
  display: flex;
  gap: 0.5rem;
  margin: 0.5rem 0;
}
.footer__social .icones {
  width: 1.3rem;
  height: 1.3rem;
}
.footer .titles {
  margin-bottom: 1.7rem;
  color: white;
}
.footer .titles__barreBottom {
  background: white;
}
.footer .link {
  color: white;
}
.footer .link:hover, .footer .link:focus {
  color: var(--color2-500);
}

.slider {
  max-width: 100vw;
  margin: auto;
}
.slider .splide.default {
  max-width: 60rem;
  margin: auto;
}
.slider .splide__pagination {
  bottom: initial;
  gap: 1rem;
  margin: 1rem auto;
  justify-content: start;
  padding: 0;
}
.slider .splide__arrows {
  display: none;
}
.slider .splide__arrow {
  background: transparent;
  color: var(--color2-500);
  opacity: 1;
}
.slider .splide__arrow .icones {
  width: 2.5rem;
  height: 2.5rem;
}

.pagination.splide__pagination__page {
  background: var(--color1-500);
  border-radius: 0;
  height: 3px;
  opacity: 1;
  width: 15px;
  margin: 0;
  display: flex;
  transition: height 0.2s ease-in-out;
}

.pagination.splide__pagination__page.is-active {
  background: var(--color2-500);
  transform: scale(1);
  height: 15px;
}

.pagination {
  display: flex;
  justify-content: center;
}
.pagination__item {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 1.5rem;
  height: 1.5rem;
  padding: 1rem;
  color: var(--color1-500);
  background: white;
  font-size: 1.2rem;
}
.pagination__active {
  color: var(--color2-500);
  position: relative;
}
.pagination__active:before {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  height: 3px;
  background: var(--color2-500);
}
.pagination__arrows {
  padding: 0;
}
.pagination__arrows:hover {
  color: var(--color1-700);
}
.pagination__bullets {
  justify-content: center;
  padding: 4rem;
}
.pagination__bullets .pagination__item {
  padding: 0;
  border: 2px solid var(--color1-500);
  margin: 0 0.5rem;
  position: relative;
}
.pagination__bullets .pagination__item:before {
  content: "";
  width: 0px;
  height: 0px;
  display: block;
  background: var(--color2-500);
  transition: all 0.3s ease-in-out;
}
.pagination__bullets .pagination__item:hover::before {
  width: 10px;
  height: 10px;
}
.pagination__bullets .pagination__active {
  background: transparent;
}
.pagination__bullets .pagination__active:before {
  width: 10px;
  height: 10px;
}
.pagination__bullets .pagination__item:not(.pagination__active, .pagination__arrows):hover {
  background: transparent;
}

.header .splide__pagination {
  justify-content: center;
  bottom: 1rem;
}

.sections__max48 {
  max-width: 48rem;
  margin: auto;
}
.sections__max74, .imageCol2, .imageCol3, .forms {
  max-width: 74rem;
  margin: auto;
}

.map {
  min-height: 20rem;
}

.breadcrumbs__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-transform: uppercase;
}
.breadcrumbs__items {
  display: flex;
}
.breadcrumbs__items::after {
  display: block;
  content: "";
  width: 0.5rem;
  mask: url("data:image/svg+xml,%3Csvg width='5' height='15' viewBox='0 0 5 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M3.63636 0H5L1.36364 15H0L3.63636 0Z' fill='%23C4C4C4'/%3E %3C/svg%3E");
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: center;
  margin: 0 0.5rem;
  background-color: var(--color2-500);
}
.breadcrumbs__items:last-child {
  color: var(--color2-500);
}
.breadcrumbs__items:last-child:after {
  display: none;
}
.breadcrumbs__name {
  display: flex;
  align-items: center;
  height: 1rem;
}
.breadcrumbs__links {
  color: white;
}
.breadcrumbs__links:hover, .breadcrumbs__links:focus {
  color: var(--color2-500);
}

.errorPage {
  position: relative;
  color: white;
}
.errorPage__image:before {
  content: "";
  background: var(--color3-800);
  opacity: 0.6;
}
.errorPage__content {
  position: relative;
  z-index: 5;
  min-height: 700px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.errorPage__statut {
  font-size: 9rem;
  line-height: 10rem;
  color: var(--color2-500);
  font-weight: 500;
}
.errorPage__buttons {
  margin-top: 1rem;
}

/* _video
========================================================================== */
/* Video
   ========================================================================== */
.video.video-js {
  width: 100%;
  max-height: 550px;
  height: 100%;
  aspect-ratio: 1;
  margin: auto;
  display: flex;
}
.video.video-js .vjs-big-play-button {
  font-size: 3em;
  width: 2em;
  height: 2em;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: var(--color2-500);
  border-radius: 0;
  transition: all 0.4s;
  border: none;
  display: block;
  transform: translate(20%);
}
.video.video-js .vjs-icon-placeholder {
  display: flex;
}
.video.video-js .vjs-big-play-button .vjs-icon-placeholder::before {
  position: relative;
  color: white !important;
}
.video.video-js:hover .vjs-big-play-button {
  background-color: var(--color2-800);
}

.vjs-playing .vjs-big-play-button {
  display: none !important;
}

.team {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 2rem;
  margin: auto;
  padding: 0;
  justify-content: center;
}
@media screen and (min-width: 640px) {
  .team {
    max-width: 64rem;
  }
}
.team__image {
  z-index: 0;
  height: 100%;
}
.team__content {
  padding: 4rem;
  background: rgba(57, 30, 31, 0.7882352941);
  z-index: 5;
  color: white;
  background-blend-mode: darken;
  text-align: center;
  transform: translate(0%, 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.team__item {
  padding: 0;
  background: white;
  position: relative;
  overflow: hidden;
  width: calc(33.3333333333% - 2rem);
  min-width: 250px;
  min-height: 450px;
  flex-grow: initial;
}
@media screen and (max-width: 639px) {
  .team__item {
    min-width: 300px;
  }
}
.team__item:hover .team__content, .team__item:focus .team__content {
  transform: translate(0%, 0%);
}
.team .titles {
  margin: 0 auto;
}

.masonry {
  display: grid;
  gap: 1rem;
}
.masonry__item {
  height: 300px;
  position: relative;
  overflow: hidden;
}
.masonry__item.row-span-1 {
  height: auto;
}
@media screen and (max-width: 639px) {
  .masonry__item.row-span-1 {
    height: 300px;
  }
}
.masonry__item .titles {
  margin-bottom: 0.5rem;
}
.masonry__item:hover .masonry__wrapper, .masonry__item:focus .masonry__wrapper {
  height: 100%;
}
.masonry__item:hover .images__cover, .masonry__item:focus .images__cover {
  transform: translateX(10px) scale(1.1);
}
.masonry__item:hover .barre, .masonry__item:focus .barre {
  width: 100%;
}
.masonry__wrapper {
  top: initial;
  height: 0;
  overflow: hidden;
}
.masonry__content {
  width: 80%;
  margin: auto;
  background: white;
  padding: 2rem;
  z-index: 5;
  position: absolute;
  bottom: 0%;
  transform: translateX(-50%) translateY(-20%);
  left: 50%;
  max-height: 200px;
  overflow: hidden;
}
.masonry .barre {
  height: 0.2rem;
  background: white;
  width: 0;
  position: absolute;
  bottom: 20%;
}

.cta {
  position: relative;
  min-height: 500px;
  color: currentColor;
}
.cta__content {
  margin: auto;
  text-align: center;
  z-index: 50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 50rem;
  padding: 0 2rem;
}
.cta__overlay {
  background: var(--color3-800);
  opacity: 0.8;
}
.cta .titles {
  color: currentColor;
}

.testimonial {
  gap: 0;
  height: fit-content;
}
.testimonial__content {
  background: white;
  z-index: 5;
  padding-bottom: 5rem;
}
@media screen and (min-width: 768px) {
  .testimonial__content {
    padding: 3rem;
    margin: 3rem 0;
  }
}
.testimonial__image {
  padding: 0;
  position: relative;
}
@media screen and (min-width: 768px) {
  .testimonial__image {
    left: -20%;
    width: 120%;
  }
}

.article {
  position: relative;
}
.article__image {
  height: 400px;
  display: block;
  background: var(--color2-500);
}
.article__infos {
  display: flex;
  flex-wrap: wrap;
  margin: 1.5rem 0;
  gap: 1rem;
}
.article__infos > * {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.article__infos > * p:after {
  content: ",";
  padding: 0 0.2rem;
}
.article__infos > * p:last-child:after {
  content: "";
}
.article .titles {
  margin-bottom: 0;
}
.article__description {
  margin: 1rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-center p {
  text-align: center;
}
.text-center .button__group {
  justify-content: center;
}

.text-right .titles {
  margin-left: auto;
  margin-right: 0;
}
.text-right li {
  direction: rtl;
  padding-right: 1.2rem;
  padding-left: 0;
}
.text-right li::before {
  left: initial;
  right: 0;
}
.text-right .button__group {
  justify-content: flex-end;
}