@use "sass:math";

//Theme
$theme: null !default;

// Colors
$colors: (
    primaire: #2c2c2c,
    secondaire: #f65354,
    tertiaire: #14143c,
)!default;

// @each $name, $hex in $colors {

//     $name: map-get($map: $colors, $key: #{$name});    

// }
$primaire: map-get($map: $colors, $key: primaire) !default;
$secondaire:  map-get($map: $colors, $key: secondaire) !default;
$tertiaire:  map-get($map: $colors, $key: tertiaire) !default;

$colorsVariantes: (
    color1:(
        100: tint($primaire, 80%),
        200: tint($primaire, 60%),
        300: tint($primaire, 40%),
        400: tint($primaire, 20%),
        500: $primaire,
        600: shade($primaire, 20%),
        700: shade($primaire, 40%),
        800: shade($primaire, 60%),
        900: shade($primaire, 80%),
    ),
    color2:(
        100: tint($secondaire, 80%),
        200: tint($secondaire, 60%),
        300: tint($secondaire, 40%),
        400: tint($secondaire, 20%),
        500: $secondaire,
        600: shade($secondaire, 20%),
        700: shade($secondaire, 40%),
        800: shade($secondaire, 60%),
        900: shade($secondaire, 80%),
    ),
    color3:(
        100: tint($tertiaire, 80%),
        200: tint($tertiaire, 60%),
        300: tint($tertiaire, 40%),
        400: tint($tertiaire, 20%),
        500: $tertiaire,
        600: shade($tertiaire, 20%),
        700: shade($tertiaire, 40%),
        800: shade($tertiaire, 60%),
        900: shade($tertiaire, 80%),
    ),
) !default;

$primaire-1 : var(--color1-100);
$primaire-2 : var(--color1-200);
$primaire-3 : var(--color1-300);
$primaire-4 : var(--color1-400);
$primaire : var(--color1-500);
$primaire-6 : var(--color1-600);
$primaire-7 : var(--color1-700);
$primaire-8 : var(--color1-800);
$primaire-9 : var(--color1-900);

$secondaire-1 : var(--color2-100);
$secondaire-2 : var(--color2-200);
$secondaire-3 : var(--color2-300);
$secondaire-4 : var(--color2-400);
$secondaire : var(--color2-500);
$secondaire-6 : var(--color2-600);
$secondaire-7 : var(--color2-700);
$secondaire-8 : var(--color2-800);
$secondaire-9 : var(--color2-900);

$tertiaire-1: var(--color3-100);
$tertiaire-2: var(--color3-200);
$tertiaire-3: var(--color3-300);
$tertiaire-4: var(--color3-400);
$tertiaire: var(--color3-500);
$tertiaire-6: var(--color3-600);
$tertiaire-7: var(--color3-700);
$tertiaire-8: var(--color3-800);
$tertiaire-9: var(--color3-900);

// Fonts
$fontsVariantes: (
    1: "futura-pt, sans-serif",
    2: "ailerons, sans-serif",
) !default;

$sizes: (
    640px, 
    768px, 
    1024px, 
    1280px,
    1536px,
)!default;
// $sizes: 640px, 768px, 1024px, 1280px, 1536px;
$radius: 0 !default;
$padding-base: 2rem !default;
$grid-gap-x: 2rem !default;
$grid-gap-y: 2rem !default;
$bg-menu: white !default;
$sizeMenu: 80px !default;

// Borders
$border-size: .3rem !default;
$border-color: $primaire !default;
$border-style: solid !default;

// Fonts
$font-1: var(--font-family1) !default;
$font-2: var(--font-family2) !default;
$font-base: 16px !default;
$font-weight: 500 !default;

// Body
$body-color: $primaire !default; 
$body-font-weight: 200 !default; 
$body-font-size: $font-base !default; 
$body-selection-color: white !default; 
$body-selection-bg: $secondaire !default; 

// Titles
$title-sizeCoef: 7 !default;
$title-font-weight: 200 !default;
$title-line-height: 1.3em !default;
$title-font: $font-2 !default;
$title-transform: initial !default;
$title-color: currentColor !default;
$title-ontitle: 1.1em !default;
$title-ontitle-color: currentcolor !default;
$title-ontitle-transform: uppercase !default;
$title-subtitle: 1.1em !default;
$title-subtitle-color: currentcolor !default;
$title-border-color: $primaire !default;

// Links
$links-color: $secondaire !default;
$links-color-hover: $primaire !default;
$links-light-color: $primaire !default;
$links-light-color-hover: $secondaire !default;
$links-white-color-hover: $primaire !default;

// Buttons
$focus-visible: $secondaire !default;

// Animations
$animations-transition-duration: .4s !default;
$animations-transition-property: all !default;
$animations-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !default;
$animations-transition-delay: 1s !default;
$animations-transform-scale: 1.1 !default;

// Buttons
$buttons-color: $secondaire !default;
$buttons-bgColor: $primaire !default;
$buttons-radius: $radius !default;
$buttons-border: math.div($border-size, 3) $border-style $secondaire !default;
$buttons-font-family: $font-1 !default;
$buttons-font-size: 1rem !default;
$buttons-padding-x: math.div($padding-base, 2)  !default;
$buttons-padding-y: math.div($padding-base, 1.5) !default;
$buttons-hover-color: $secondaire-1 !default;
$buttons-hover-border-color: $primaire !default;

// Accordions
$accordion-border-color: $secondaire !default;
$accordion-padding: 2rem !default;

// Pagination
$pagination-color: $secondaire !default;
$pagination-bgColor: transparent !default;
$pagination-color-active: white !default;
$pagination-bgColor-active: $primaire !default;
$pagination-width: 2rem !default;
$pagination-height: 2rem !default;
$pagination-margin-x: 1rem !default;
$pagination-margin-y: 0 !default;
$pagination-radius: 0 !default;
$pagination-opacity: 1 !default;

// Pagination slider
$pagination-slider-bgColor: $primaire !default;
$pagination-slider-padding-x: .3rem !default;
$pagination-slider-padding-y: .3rem !default;

// Slider
$slider-margin-y: 2rem !default;

// List
$list-bullet-size: .4rem !default;
$list-bullet-offset: addition($list-bullet-size, .8rem) !default;
$list-bullet-width: $list-bullet-size !default;
$list-bullet-height: $list-bullet-size !default;
$list-bullet-bgColor: $secondaire !default;
$list-bullet-radius: $radius !default;

// Footer
$footer-color: white !default;
$footer-bgColor: $secondaire !default;
$footer-barre: 0.1rem solid $secondaire !default;
$footer-copyright-padding: 2rem !default;
$footer-padding: 1rem !default;
$footer-titles-marge-bottom: 1rem !default;
$footer-link-color: $secondaire !default;
$footer-link-color-hover: $secondaire-1 !default;
$footer-logo-max-height: 4rem !default;
$footer-logo-min-width: 40px !default;

// Divider
$divider-color: $secondaire !default;
$divider-height: .1rem !default;
$divider-border: none !default;
$divider-marge-y: 2rem !default;

// Overlay
$overlay-color: white !default;

// Breadcrumbs
$breadcrumbs-color: $primaire !default;
$breadcrumbs-color-hover: $secondaire !default;
$breadcrumbs-marge-y: 1rem !default;
$breadcrumbs-marge-x: 0 !default;
$breadcrumbs-position: center !default;
$breadcrumbs-text-transform: initial !default;
$breadcrumbs-divider-color: $primaire !default;
$breadcrumbs-active: $secondaire !default;

// Headers
$headers-border-bottom: .2rem solid $secondaire !default;
$headers-padding-y: 4rem !default;
$headers-padding-y-lg: 4rem !default;

// Features
$features-padding-y: 1rem !default;
$features-padding-x: 0 !default;

// Pricing
$pricing-detail-width: 25rem !default;
$pricing-detail-font-size: .9rem !default;
$pricing-dotted-line-color: $primaire !default;

// Aside
$aside-fixed-width: 400px !default;


// // Icon
// $icons :(
//     'close',
//     'burger',
//     'arrow-top',
//     'arrow-right',
//     'arrow-bottom',
//     'arrow-left',
//     'minus',
//     'plus',
//     'facebook',
//     'instagram',
//     'linkedin',
//     'date',
//     'clock',
//     'location',
//     'phone',
//     'mail',
//     'star',
//     'star-f',  
// )!default;


