.text-center{
    .titles{
        @extend .titles__center;
    }
    p{
       text-align: center;
    }

    .button__group{
        justify-content: center;
    }
}
.text-right{
    .titles{
        margin-left: auto;
        margin-right: 0;
    }

    li{
        direction: rtl;
        padding-right: 1.2rem;
        padding-left: 0;

        &::before{
            left: initial;
            right: 0;
        }
    }

    .button__group{
        justify-content: flex-end;
    }
}