.footer{

    background: $tertiaire-8;
    color: white;

    .grid{
        grid-template-columns: repeat(1, minmax(0, 1fr));

        @media screen and (min-width: 768px){ 
            grid-template-columns: repeat(3, minmax(0, 1fr));

            &__cel:first-child{
                grid-column: span 3 / span 3;
            }
        }


        @media screen and (min-width: 1024px){ 
            grid-template-columns: repeat(4, minmax(0, 1fr));

            &__cel:first-child{
                grid-column: span 1 / span 1;
            }
        }
        
    }

    &__logo{
        // max-height: 45px;
        // margin-bottom: .5rem;
        max-height: 115px;
        margin-bottom: 1.3rem;
    }

    &__list{

        @extend .list__bullet;

        li{
            @extend .list__item;
            padding: .1rem 1rem;

            &::before{
                background: $secondaire;
            }
        }
    }   


    &__copyright{
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        text-transform: uppercase;
        
        @media screen and (min-width: 640px){ 
            justify-content: space-between;  
            align-items: center;
            flex-direction: row; 
            
            .footer__social{
                direction: rtl;
            }
            
            *:nth-child(1){
                order: 2;
            }
        }
    }

    &__social{
        display: flex;
        gap: .5rem;
        margin: .5rem 0;

        .icones{
            width: 1.3rem;
            height: 1.3rem;
        }
    }

    .titles{
        margin-bottom: 1.7rem;
        color: white;

        &__barreBottom{
            background: white;
        }
    }

    .link{
        color: white;

        &:hover,
        &:focus{
            color: $secondaire;
            @extend %transition;
        }
    }
}