.team{
    display: flex;
    flex-wrap: wrap;
    gap: 2rem 2rem;
    margin: auto;
    padding: 0;
    justify-content: center;

    @media screen and (min-width: 640px){ 
        max-width: 64rem;
    }
    
    
    &__image{
        z-index: 0;
        height: 100%;
        
    }
    
    &__content{
        padding: 4rem;
        @extend %absolute0;
        background: #391e1fc9;
        z-index: 5;
        color: white;
        background-blend-mode: darken;
        text-align: center;
        transform: translate(00%, 100%);
        @extend %transition;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }
    
    &__item{
        padding: 0;
        background: white;
        position: relative;
        overflow: hidden;
        width: calc( 33.3333333333% - 2rem);
        min-width: 250px;
        min-height: 450px;
        flex-grow: initial;
        
        @media screen and (max-width: 639px){ 
            min-width: 300px;      
        }

        &:hover,
        &:focus{

            .team__content{
                transform: translate(0%, 0%);
            }
         }

    }
    .titles{
        margin: 0 auto;
    }
}