.tags{
    margin-right: .5rem;
    margin-bottom: .5rem;
    user-select: none;    

    input{
        display: none;

        &:checked ~ label{
            color: white;
            background-color: $secondaire;
        }
    }

    label,
    > *{
        padding: .5rem 1rem;
        border: $border-size solid $secondaire;
        text-transform: uppercase;
        height: auto;
        display: block;
    }
}