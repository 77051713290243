#topButton{ 

    @extend %transition;
    bottom: 2.5rem;
    right: 2.5rem;
    visibility: hidden;
    background: $secondaire;
    padding: 0.5rem;
    color: white;
    width: 3rem;
    height: 3rem;
    z-index: 9999;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 768px){ 
        visibility: visible;
    }

    &.hide{
        transform: translateY(200%);
    }
}  