.sections{

    $sizes: '48', '74' ;

    @each $size in $sizes {
        
        &__max#{$size}{
            max-width: #{$size}rem;
            margin: auto;
        }
    }

}