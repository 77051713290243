.cards{
    display: grid;
    gap: 0 2rem;

    @media screen and (min-width: 640px){ 

        &__content{
            padding: 2rem;
        }
    }

    @media screen and (min-width: 768px){ 
        grid-template-columns: repeat(2, minmax(0, 1fr));

        &__inverse{
            .cards__image{
                order: 2;
            }
        }
    }
}