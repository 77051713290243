&__mutiSelect{

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: auto;
   
    &-wrapper{
        position: relative;
        display: inline-block;
        width: 100%;
    }

    &-input{
        flex: 1 1 0%;
        
        input{
            width: 100%;
            height: 100%;
            padding: .25rem .5rem;
            outline: 2px solid transparent;
            outline-offset: 2px;
        }
    }

    &-chevrons{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .5rem 1rem;
        margin: -1rem;
        color: white;
        background: $primaire;

        [type="button"]{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &-dropdown{
        width: 100%;
        // padding: 1rem 0;

        &-wrapper{
            position: absolute;
            left: 0;
            z-index: 40;
            width: 100%;
            background: white;
            box-shadow: var(--shadow-b);
        }

        &-options{
            display: flex;
            flex-direction: column;
            width: 100%;
            overflow-y: auto;
        }

        &-option{
            overflow: auto;
        }

        &-item{

            width: 100%;
            border-bottom-width: 1px;
            border-color: $primaire;
            cursor: pointer;

            &:hover{
                // background: $secondaire;
            }

            &:last-child{
                border-bottom: none;
            }
        }
        

    }

    .forms__input{

        &-wrapper{
            display: flex;
            flex-wrap: wrap;
            flex: 1 1 auto;
        }

        &-options{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.25rem;
            margin: 0.25rem;
        }

        &-selected{
            font-size: 0.75rem;
            line-height: 1rem;
            line-height: 1;
            max-width: 100%;
            flex: 0 1 auto;
        }

        &-icones{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 0.75rem;
            height: 0.75rem;
            margin-left: 0.5rem;
        }

    }

    
    
}