[type="button"],
.button{
    -webkit-appearance: none;
}

.button{

    @include background('secondaire');
    padding: 1rem 1.5rem;
    display: inline-flex;
    align-items: center;
    font-family: $font-1;
    font-size: 1rem;
    border-radius: $radius;
    width: max-content;
    text-transform: uppercase;
    position: relative;

    &:after,
    &:before{
        content: '';
        width: 0%;
        background: $primaire;
        height: 53%;
        position: absolute;
        right: 0;
        bottom: -1px;
    }

    &:after{
        top: -1px;
        left: 0;
    }

    &:hover,
    &:focus{
        
        &:after,
        &:before{
            transition-property: all;
            transition-timing-function: ease;
            transition-duration: .5s;
            width: 100%;

        }
    }
    
    &__content{
        position: relative;
        z-index: 10;
        display: flex;
        align-items: center;
        line-height: 1;
        color: white;
    }

    &__border{
        border: 0.15rem solid var(--color1-500);
    }

    &__icones{
        padding: 1rem;
    }

    .icones{
        width: 1rem;
        height: 1rem;
        z-index: 10;
    }


    &__secondary{
        @include background('secondaire');

        &.button__border{
            border-color: $primaire;
        }
    }

    &:hover,
    &:focus{
        @extend %transition;
        background: $secondaire;
        color: white;
        border-color: var(--color1-500);
        
    }

    &__light{
        background: white;
        color: $secondaire;
        border-color: $primaire;

        .button__content{
            color: var(--color2-500);
        }

        &:hover{
            .button__content{
                color: white;
            }
        }
    }
}

.button__group{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: .5rem
}


// @import "btnIcon.scss";
@import "backTopBtn.scss";


