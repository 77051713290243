.menu {

    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    // background: $tertiaire-8;
    z-index: 9999;
    // @extend .wrapper;

    @media screen and (max-width: 767px){ 
        background: $tertiaire-8;
    }

    .wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: initial;
        padding: 2rem;

        @media screen and (max-width: 767px){ 
            flex-direction: column;
            align-items: self-start;
        }
    
        @media screen and (min-width: 1024px){ 
            @include wrapper;
        }
    }

    & + *{
        

        @media screen and (min-width: 768px){ 
            margin-top: -105px;
        }
    }

    &__logo{
        display: flex;
        align-items: center;
        position: relative;
        z-index: 5;

        img{
            display: block;
            width: auto;
            height: 2rem;
        }
    }

    &__bg{
        position: absolute;
        left: 0;
        right: 0;
        background: $tertiaire-8;
        bottom: 100%;
        top: 0;
        @extend %transition; 
    }

    &.show .menu__bg{
        bottom: 0;
    }

    &.show.hide .menu__bg{
        bottom: 100%;
    }

    .link{
        padding: .5rem;
        color: white;
    }

    .dropdown .link{
        color: $secondaire;
        display: flex;
        align-items: center;
        color: $tertiaire-8;
        @extend %transition;

        span{
            width: 0;
            background: $secondaire;
            display: block;
            height: .1rem;
            margin-right: .5rem;
            @extend %transition;
        }

        &:hover{
            color: $secondaire;

            span{
                width: 2rem;
            }
        }
    }
}

#navCheck{
    position: absolute;
    left: -999;
    top: -999;
    visibility: hidden;
}

#navCheck:checked ~ .nav{
    @media screen and (max-width: 767px){ 
        // flex-direction: column;
        // align-items: self-start;
        padding: 1rem 2rem;
        height: 100%;
        width: 100%;
    }
}


#navCheck ~ .buttons .menu__button .icones__close{
    display: none;
}

#navCheck:checked ~ .buttons .menu__button .icones__burger{
    display: none;
}

#navCheck:checked ~ .buttons .menu__button .icones__close{
    display: block;
}

.menu__button{
    
    @media screen and (min-width: 768px){ 
        display: none;
    }
}

.nav {
	display: flex;
	align-items: center;
    z-index: 999;

    @media screen and (max-width: 767px){ 
        flex-direction: column;
        align-items: self-start;
        // padding: 1rem 2rem;

        height: 0;
        overflow: auto;
        padding: 0;
    }
    

	&__item {
		display: inline-flex;
		align-items: center;
		padding: 1rem;

		text-decoration: none;
		color: white;

		&:hover {
			color: darken(white, 10%);
		}
	}

    .link{
        &.active{
            position: relative;
            &:before{
                content:'';
                width: 100%;
                height: 3px;
                display: block;
                background: $secondaire;
                position: absolute;
                bottom: 0;
                left: 0;
                @extend %transition;
            }

            &:hover{
                &:before{
                    left: .2rem;
                }
            }
        }
    }
}

.dropdown {
	position: relative;
    @media screen and (min-width: 768px){ 
        z-index: -1;
    }


	&__btn {
		position: relative;
		display: inline-flex;
		padding: .5rem;
		border: none;

		font-size: inherit;
		color: white;
		font-family: inherit;

		cursor: pointer;
		background-color: transparent;
		border-radius: 3px;
		z-index: 2;


	}

	&__list {
		position: absolute;
		left: 0;
		width: max-content;
        z-index: -1;
		display: flex;

		flex-direction: column;
		padding-top: 10px;

		overflow: hidden;

		transform: translateY(-20%);
		visibility: hidden;
		opacity: 0;
		transition: all 0.25s;
		z-index: 1;
        background: white;
        padding: 1rem;


	}

	&__item {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		padding: 1rem 1.5rem;
		font-size: 1rem;

		text-decoration: none;
		color: $secondaire;
		background-color: rgba(#7066cc, 0.1);
		transition: background-color 0.2s;

		&:first-child {
			border-radius: 3px 3px 0 0;
		}

		&:hover {
			background-color: $secondaire;
		}

		span {
			height: 1rem;
			width: 1rem;
			margin-left: 10px;

			display: inline-flex;
			align-items: center;
			justify-content: center;

			font-size: 0.65rem;
			color: #fff;

			background-color: #7066cc;
			border-radius: 50%;
		}
	}

	// Show The dropdown content
	&:hover {
		.dropdown__btn {
			color: $secondaire;
		}

		.dropdown__list {
			transform: translateY(10px);
			visibility: visible;
			opacity: 1;

		}
	}

    .link.active:before{
        display: none;
    }
}
