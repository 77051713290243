%absolute0 {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
}
%flexCenter {
	display: flex;
	align-items: center;
}

%flexCenterCenter {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

%flexCol {
	display: flex;
	flex-direction: column;
}

%flexColCenter {
	display: flex;
	flex-direction: column;
	align-items: center;
}
%imgCover {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

%imgContain {
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
}

%transition {
	transition-property: $animations-transition-property;
	transition-timing-function: $animations-transition-timing-function;
	transition-duration: $animations-transition-duration;
}

%verticalText{
	writing-mode: vertical-lr;
	transform: rotate(180deg);
	justify-self: baseline;
}

%border{
    border: $border-size $border-style $border-color;
}
