
.pagination.splide__pagination__page{
    background: $primaire;
    border-radius: 0;
    height: 3px;
    opacity: 1;
    width: 15px;
    margin: 0;
    display: flex;
    transition: height .2s ease-in-out;

    // &.is-active{
    //     background: $secondaire;
    //     height: 15px;
    //     transform: scale(1);
    // }
}

.pagination.splide__pagination__page.is-active {
    background: $secondaire;
    transform: scale(1);
    height: 15px;
}


.pagination{

    display: flex;
    justify-content: center;

    &__item{
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        width: 1.5rem;
        height: 1.5rem;
        padding: 1rem;  
        color: $primaire; 
        background: white;
        font-size: 1.2rem
        
        // &:not(.pagination__active, .pagination__arrows ):hover{
        //     transition: all .5s ease-in-out;
        //     color: white;
        //     background: $secondaire;
        // }
    }

    &__active{
        @extend %transition;
        color: $secondaire;
        position: relative;

        &:before{
            content: '';
            width: 100%;
            display: block;
            position: absolute;
            bottom: 0;
            height: 3px;
            background: $secondaire;
        }
    }

    &__arrows{
        padding: 0;

        &:hover{
            @extend %transition;
            color: $primaire-7;
        }
    }

    @import "paginationBullets.scss";
}

.header .splide__pagination{
    justify-content: center;
    bottom: 1rem;
}
