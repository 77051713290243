::selection {
    background-color: $secondaire-1;
    color: $primaire;
}

html{
    overflow-x: hidden;
    font-weight: 200;
    font-size: $font-base;
    font-family: $font-1;
    scroll-behavior: smooth;
    color: $primaire; 
}

.wrapper{
    @include wrapper;
    padding-left: 1rem;
    padding-right: 1rem;
    @media screen and (min-width: 768px){
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

html,
body {
   height: 100%;
}
body {
   display: flex;
   flex-direction: column;
}
.content {
   flex: 1 0 auto;
}
.footer {
   flex-shrink: 0;
}

.ancres {
    padding-top: 11rem !important;
    margin-top: -8rem !important;
}