.forms{
    
    color: $primaire;
    @extend .sections__max74;

    &.grid{
        gap: 1rem;
    }

    .grid{
        gap: 1rem;
    }

    &__label{
        display: flex;
        flex-direction: column;
        padding: .5rem;
        font-size: 1.125rem;
        line-height: 1.75rem;
        color: $secondaire;
        text-transform: uppercase;
    }

    &__input,
    &__textarea{
        display: block;
        padding: 1rem;
        width: 100%;
        border: none;
        border-bottom: .1rem solid $secondaire;
        // @extend %border;


        &:focus{
            box-shadow: none;
            transition: border-color .5s ease-in-out;
            border-color: $secondaire;
        }
    }

    &__textarea{
        resize: none;
        min-height: 10rem;
        width: 100%;
    }

    &__message{
        padding: 0 0.5rem;
        margin: 0.5rem 0;
        color: currentColor;
    }

    &__checkbox{
        position: relative;
        display: flex;
        align-items: flex-start;
        color: currentColor;

        &-inputWrapper{
            display: flex;
            align-items: center;
            height: 1.8rem;
        }
        
        &-input{
            width: 1rem;
            height: 1rem;
            color: currentColor;

            &:focus{
                outline-offset: 0px;
                box-shadow: none;
            }
        }

        .forms__label{
            color: $primaire;
            text-transform: initial;
            display: inline;
            padding: 0 .5rem ;
        }
    }

    &__footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }



    @import "select.scss";
    @import "mutiSelect.scss";
    @import "inputFiles.scss";
    @import "inputNumbers.scss";

    &__inline{
        display: flex;
        flex-wrap: wrap;
        gap: 0;

        .quantity input,
        .quantity-nav{
            height: 100%;
            border: none;
        }

        .button{
            margin: 0;
            flex:1;


            &__content{
                margin: auto;
            }
        }

        .quantity-button{
            height: 25px;
        }

        .quantity-button--up{
            transform: rotate(270deg) translate(-30%, -100%);
        }

        .quantity-button--down{
            transform: rotate(90deg) translate(-30%, 100%);
        }
    }

    [type="submit"]{
        min-width: 200px;
        justify-content: center;
        margin-top: 1rem;
    }

    .grid__col-2{
        grid-template-columns: repeat(1, minmax(0, 1fr));

        @media screen and (min-width: 1280px){ 
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

}