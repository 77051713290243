:root#{$theme}{
    @each $mapColors, $nameMapColors in $colorsVariantes {
        @each $nameColor, $color in $nameMapColors {
            --#{$mapColors}-#{$nameColor}: #{$color};
        }
    }

    @each $nameFont, $font  in $fontsVariantes {
        --font-family#{$nameFont}: #{$font};
    }
}