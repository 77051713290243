.cta{
    position: relative;
    min-height: 500px;
    color: currentColor;

    &__content{
        @extend %absolute0;
        margin: auto;
        text-align: center;
        z-index: 50;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 50rem;
        padding: 0 2rem;
    }

    &__image{
        @extend %absolute0;
    }

    &__overlay{
        background: $tertiaire-8;
        opacity: .8;
    }

    .titles{
        color: currentColor;
    }
}