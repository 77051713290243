.errorPage{
    position: relative;
    color: white;

    &__image{
        @extend %absolute0;

        &:before{
            content: '';
            @extend %absolute0;
            background:$tertiaire-8;
            opacity: .6;
        }
    }

    &__content{
        position: relative;
        z-index: 5;
        min-height: 700px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__statut{
        font-size: 9rem;
        line-height: 10rem;
        color: $secondaire;
        font-weight: 500;
    }

    &__buttons{
        margin-top: 1rem;
    }
}