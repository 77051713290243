.quantity {
    position: relative;
  
    input {
      height: 42px;
      line-height: 1.65;
      float: left;
      display: block;
      padding: 0;
      margin: 0;
      padding-left: 20px;
      border: 2px solid $primaire;
      width: 90px;
  
      &:focus {
        outline: 0;
      }
    }
  }
  
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button
  {
    -webkit-appearance: none;
    margin: 0;
  }
  
//   input[type=number]
//   {
//     -moz-appearance: textfield;
//   }
  
  .quantity-nav {
    float: left;
    position: relative;
    height: 42px;
  }
  
  .quantity-button {
    position: relative;
    cursor: pointer;
    width: 20px;
    text-align: center;
    color: white;
    font-size: 10px;
    line-height: 1.7;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    user-select: none;
    background: $primaire;
  
    &--up {
      position: absolute;
      height: 50%;
      top: 0;
      mask:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-8 -5 24 24' width='24' fill='currentColor'%3E%3Cpath d='M5.314 7.071l-4.95-4.95A1 1 0 0 1 1.778.707l5.657 5.657a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 0 1-1.414-1.414l4.95-4.95z'%3E%3C/path%3E%3C/svg%3E");
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: cover;
    }
  
    &--down {
      position: absolute;
      bottom: 0;
      height: 50%;
      mask:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-8 -5 24 24' width='24' fill='currentColor'%3E%3Cpath d='M5.314 7.071l-4.95-4.95A1 1 0 0 1 1.778.707l5.657 5.657a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 0 1-1.414-1.414l4.95-4.95z'%3E%3C/path%3E%3C/svg%3E");
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: cover;
    }
  }