/* _icons
========================================================================== */
[class*=" icon-"]{
    line-height: 1;
    z-index: 1;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: currentColor;
    
}


