.article{
    
    position: relative;

    &__image{
        height: 400px;
        display: block;
        background: $secondaire;
    }

    &__infos{
        display: flex;
        flex-wrap: wrap;
        margin: 1.5rem 0;
        gap: 1rem;

        > * {
            margin-right: .5rem;
            display: flex;
            align-items: center;
            gap: .3rem;

            p:after{
                content: ',';
                padding: 0 .2rem;
            }

            p:last-child:after{
                content: '';
            }
        }
    }

    .titles{
        margin-bottom: 0;
    }
    
    &__description{
        margin: 1rem 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

}