.filters{

    &__options{
        display: flex;
        flex-wrap: wrap;
    }

    &__header{
        background: $tertiaire-8;
        padding: 2rem;
    
        @media screen and (min-width: 640px){ 
            padding: 3rem;
            max-width: 80rem;
            margin: auto;
            margin-bottom: 4rem;
        }
        .titles{
            color: white;
        }
    }

    // .tags label{
    //     color: white;
    //     border-color: white;
    // }

    // .tags input:checked ~ label{
    //     border-color: $secondaire;
    // }

    .filter{
        border: .15rem solid white;
        color: white;

        &--selected{
            border-color: var(--color2-500);
            background-color: var(--color2-500);
        }
    }
}

.cardfilter{
    &.show{
        display: block;
    }

    &.hide{
        display: none;
    }
}