@each $mapColors, $nameMapColors in $colorsVariantes {

    @each $nameColor, $color in $nameMapColors {
        .text-#{$mapColors}-#{$nameColor}{
            color: var(--#{$mapColors}-#{$nameColor});
        }

        .bg-#{$mapColors}-#{$nameColor}{
            background-color: var(--#{$mapColors}-#{$nameColor});
        }
    }
}