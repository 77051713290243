

.grid{
    
    &__col{
        @for $i from 2 to 13 {
            &-#{$i}{
                gap: $grid-gap-y $grid-gap-x;
                grid-template-columns: repeat($i, minmax(0, 1fr));
            }
        }
        
        &-dense{
            grid-auto-flow: dense;
        }
    }
    
    &__cel{
        padding: calc(#{$padding-base} / 2) ;
    }

    @media screen and (max-width: 639px){ 
        &__col-2{
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }


    &__product{
        gap: 5rem;
        margin: 3rem auto !important
    }
}