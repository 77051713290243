&__bullets{

    justify-content: center;
    padding: ($padding-base * 2);



    .pagination__item{
        padding: 0;
        border: 2px solid $primaire;
        margin: 0 0.5rem;
        position: relative;

        &:before{
            content: '';
            width: 0px;
            height: 0px;
            display: block;
            background: $secondaire;
            transition: all .3s ease-in-out;
        }

        &:hover::before{
            width: 10px;
            height: 10px;
        }
    }

    .pagination__active{
        background: transparent;
       

        &:before{
            width: 10px;
            height: 10px;
        }
    }

    .pagination__item:not(.pagination__active, .pagination__arrows):hover{
        background: transparent;
    }

}